<script>
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  props: {
    match_id: String,
    team: Number, // 0 for home, 1 for away
    lineupHome: Array,
    lineupAway: Array,
    homeSubs: Array,
    awaySubs: Array,
    team_id: String,
    formation: String,
  },
  emits: ["response"],
  data() {
    return {
      csrf_token: localStorage.getItem("csrf_token"),
      showModal: false,
      tryingToSubmit: false,
      selectedHomeStarters: [],
      selectedHomeSubs: [],
      selectedAwayStarters: [],
      selectedAwaySubs: [],
      squad: [],
      selectedPlayerId: null,
    };
  },
  computed: {
    players() {
      return this.team ? this.selectedAwayStarters : this.selectedHomeStarters;
    },
    subs() {
      return this.team ? this.selectedAwaySubs : this.selectedHomeSubs;
    },
    startersCountClass() {
      return this.players.length === 11 ? "text-success" : "text-danger";
    },
    subsCountClass() {
      return this.subs.length === 12 ? "text-success" : "text-danger";
    },
  },
  watch: {
    lineupHome: {
      immediate: true,
      handler(newVal) {
        this.selectedHomeStarters = newVal || [];
        this.getSportSquad();
      },
    },
    homeSubs: {
      immediate: true,
      handler(newVal) {
        this.selectedHomeSubs = newVal || [];
        this.getSportSquad();
      },
    },
    lineupAway: {
      immediate: true,
      handler(newVal) {
        this.selectedAwayStarters = newVal || [];
        this.getSportSquad();
      },
    },
    awaySubs: {
      immediate: true,
      handler(newVal) {
        this.selectedAwaySubs = newVal || [];
        this.getSportSquad();
      },
    },
  },

  methods: {
    async getSportSquad() {
      try {
        const response = await Fixtures.getSportSquadRaw(this.team_id);
        const allPlayers = new Set([
          ...this.selectedHomeStarters.map((p) => p.player_id),
          ...this.selectedHomeSubs.map((p) => p.player_id),
          ...this.selectedAwayStarters.map((p) => p.player_id),
          ...this.selectedAwaySubs.map((p) => p.player_id),
        ]);
        this.squad = response.data.squad.filter(
          (player) =>
            !allPlayers.has(player.player_id) &&
            player.player_position_name !== "Staff" &&
            player.player_position_name !== "Coach"
        );
      } catch (error) {
        console.error("Error fetching squad:", error);
      }
    },
    async addPlayerToStarters() {
      if (this.selectedPlayerId) {
        const player = this.squad.find(
          (p) => p.player_id === this.selectedPlayerId
        );
        if (player) {
          this.players.push(player);
          this.squad = this.squad.filter(
            (p) => p.player_id !== player.player_id
          );
          this.selectedPlayerId = null;
        }
      }
    },
    moveToSubs(index) {
      const player = this.players.splice(index, 1)[0];
      this.subs.push(player);
      this.getSportSquad();
    },
    moveToStarters(index) {
      const player = this.subs.splice(index, 1)[0];
      this.players.push(player);
    },
    removePlayerFromStarters(index) {
      const player = this.players.splice(index, 1)[0];
      this.squad.push(player);
    },
    removePlayerFromSubs(index) {
      const player = this.subs.splice(index, 1)[0];
      this.squad.push(player);
    },
    closeModal() {
      this.showModal = false;
    },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Roster has been saved",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 5000,
      });
    },
    async submitRoster() {
      try {
        this.tryingToSubmit = true;
        const payload = {
          match_id: this.match_id,
          starters: this.team
            ? this.selectedAwayStarters
            : this.selectedHomeStarters,
          subs: this.team ? this.selectedAwaySubs : this.selectedHomeSubs,
          home_away: this.team ? "away" : "home",
        };
        const response = await Fixtures.updateSportRoster(
          this.match_id,
          payload
        );
        this.successmsg();
        this.tryingToSubmit = false;
        this.closeModal();
        this.$emit("response", response);
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.tryingToSubmit = false;
      }
    },
  },
  mounted() {
    this.getSportSquad();
  },
};
</script>

<template>
  <b-modal
    id="edit_roster"
    v-model="showModal"
    :title="team ? 'Edit Team - Away' : 'Edit Team - Home'"
    title-class="text-black font-18"
    body-class="p-3"
    hide-footer
    centered
    size="lg"
    @show="getSportSquad"
  >
    <form @submit.prevent="submitRoster">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <h5>{{ team ? "Away" : "Home" }} Team Starters</h5>
            <h6 class="text-muted">
              Formation:
              <span class="h5">{{ formation }}</span>
            </h6>
            <h6>
              <span class="text-muted">Total: </span>
              <span :class="startersCountClass">{{ players.length }}/11</span>
            </h6>
            <ul class="list-group">
              <li
                v-for="(player, index) in players"
                :key="index"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <div class="d-flex flex-column">
                  {{ player.player_name }}
                  <span class="text-muted">{{
                    player.player_position_name
                  }}</span>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-sm btn-danger mr-2"
                    @click="removePlayerFromStarters(index)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="moveToSubs(index)"
                  >
                    <i class="fas fa-arrow-right"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <div class="col-6">
            <h5>{{ team ? "Away" : "Home" }} Team Substitutes</h5>
            <h6>Total: {{ subs.length }}</h6>

            <ul class="list-group">
              <li
                v-for="(player, index) in subs"
                :key="index"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <div class="d-flex flex-column">
                  {{ player.player_name }}

                  <span class="text-muted"
                    >{{ player.player_position_name }}
                  </span>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-sm btn-danger mr-2"
                    @click="removePlayerFromSubs(index)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="moveToStarters(index)"
                  >
                    <i class="fas fa-arrow-left"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="control-label form-label">Add Player</label>
            <select
              v-model="selectedPlayerId"
              class="form-control form-select"
              name="player_id"
              type="select"
            >
              <option :value="null">Please select a player</option>
              <option
                v-for="player in squad"
                :key="player.player_id"
                :value="player.player_id"
              >
                {{ player.player_name }}

                <small class="text-muted">{{
                  player.player_position_name
                }}</small>
              </option>
            </select>
            <button
              @click="addPlayerToStarters"
              class="mt-2 btn btn-primary"
              type="button"
            >
              Add Player
            </button>
          </div>
        </div>

        <div class="text-end pt-5 mt-3">
          <b-button variant="light" @click="closeModal">Close</b-button>
          <b-button type="submit" variant="primary" class="ms-3">
            {{ this.tryingToSubmit ? "Loading..." : "Submit" }}
          </b-button>
        </div>
        <input type="hidden" name="csrf_token" v-model="csrf_token" />
      </div>
    </form>
  </b-modal>
</template>
