<script>
import Fixtures from "@/services/Fixtures";
import Http from "@/services/Http";
import Swal from "sweetalert2";
export default {
  props: {
    eventTypeId: Number, //
    eventName: String,
    eventMinute: String,
    eventPlayer: String,
    eventSecondPlayer: String,
    home_away: String,
    eventReason: String,
    match_id: String,
    eventPlayerId: String,
    eventReasonId: Number,
    eventHomeGoals: Number,
    eventAwayGoals: Number,
  },
  data() {
    return {
      eventType: this.eventTypeId > 4 && this.eventTypeId <= 11,
      lastEventPeriod: null,
    };
  },
  computed: {
    field() {
      return this.eventType ? this.eventPlayer : this.eventName;
    },
    ownGoalFill() {
      return this.eventReasonId === 8 ? "red" : "";
    },
  },
  methods: {
    renderIcon() {
      switch (this.eventTypeId) {
        case 5:
          return this.renderGoalIcon();
        case 6:
          return this.renderYellowCardIcon();
        case 7:
          return this.renderRedCardIcon();
        case 8:
          return this.renderYellowRedCardIcon();
        case 9:
          return this.renderMissedPenaltyIcon();
        case 10:
          return this.renderSubstitutionIcon();
        case 11:
          return this.renderVARIcon();
      }
    },
    renderGoalIcon() {
      const fill = this.ownGoalFill || "black";
      return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="${fill}"">
          <path d="M17 2.93a9.96 9.96 0 1 0-14.08 14.1A9.96 9.96 0 0 0 17 2.92Zm.41 2.77a8.5 8.5 0 0 1 1.1 3.43L16.66 8.1l.75-2.4Zm-1.37-1.8.37.4-1.11 3.57-1.33.4-3.32-2.41V4.5l3.16-2.2a8.6 8.6 0 0 1 2.22 1.6ZM9.96 1.4c.78-.01 1.55.1 2.3.3l-2.3 1.6-2.3-1.6c.75-.2 1.52-.31 2.3-.3ZM3.9 3.9a8.6 8.6 0 0 1 2.22-1.6l3.16 2.2v1.36l-3.32 2.4-1.32-.4L3.52 4.3l.37-.4ZM2.52 5.7l.75 2.4-1.85 1.03a8.5 8.5 0 0 1 1.1-3.43Zm1.37 10.35-.22-.23H5.7l.65 1.95a8.6 8.6 0 0 1-2.45-1.72Zm2.01-1.6H2.63A8.5 8.5 0 0 1 1.4 10.7l2.75-1.55 1.41.43 1.28 3.91-.95.95Zm6.05 3.89c-1.3.3-2.66.3-3.97 0l-1.01-3.02 1.1-1.1h3.79l1.1 1.1-1.01 3.02Zm-.07-5.44H8.05L6.86 9.25 9.96 7l3.1 2.25-1.18 3.65Zm4.15 3.15a8.6 8.6 0 0 1-2.45 1.72l.66-1.94h2.01l-.22.22Zm-2-1.6-.95-.95 1.27-3.91 1.41-.43 2.76 1.55a8.5 8.5 0 0 1-1.22 3.74h-3.27Z"/>
        </svg>`;
    },
    renderYellowCardIcon() {
      return '<div class="yellow-card"></div>';
    },
    renderRedCardIcon() {
      return '<div class="red-card"></div>';
    },
    renderYellowRedCardIcon() {
      return '<div class="yellow-red-card"></div>';
    },
    renderMissedPenaltyIcon() {
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" viewBox="0 0 24 24">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2V7zm1 11.75c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/>
        </svg>`;
    },
    renderSubstitutionIcon() {
      return `
        <img src="https://s3-eu-west-1.amazonaws.com/stadiaconnect-cdn/icons/substitution.png" width="28" height="28" alt="Substitution" />`;
    },
    renderVARIcon() {
      return `
        <img  width="30" height="20" src="//upload.wikimedia.org/wikipedia/commons/thumb/6/6f/VAR_System_Logo.svg/429px-VAR_System_Logo.svg.png" alt="VAR" />`;
    },
    renderDivider(eventTypeId) {
      switch (eventTypeId) {
        case 1: // Start
          return `<div class="event-divider">Kick Off</div>`;
        case 2: // Half Time
          return `<div class="event-divider">Half Time</div>`;
        case 3: // Start Second Half
          return `<div class="event-divider">Start Second Half</div>`;
        case 4: // End
          return `<div class="event-divider">End of Period</div>`;
        case 12: // Extra Time - First Half Start
          return `<div class="event-divider">Extra Time - First Half Start</div>`;
        case 13: // Extra Time - First Half End
          return `<div class="event-divider">Extra Time - First Half End</div>`;
        case 14: // Extra Time - Second Half Start
          return `<div class="event-divider">Extra Time - Second Half Start</div>`;
        case 15: // Extra Time - Second Half End
          return `<div class="event-divider">Extra Time - Second Half End</div>`;
        case 16: // Penalty Shootout Start
          return `<div class="event-divider">Penalty Shootout Start</div>`;
        case 17: // Second Half End
          return `<div class="event-divider">Second Half End</div>`;
        default:
          return "";
      }
    },
  },
};
</script>

<template>
  <div class="event-table mb-2">
      <!-- divider for the event period -->
      <div v-html="renderDivider(eventTypeId)"></div>
    <!-- Home Team  -->
    <div v-if="home_away === 'home' && eventType" class="event-content home">
      <p class="event-minute fw-bold">{{ eventMinute }}</p>
      <div class="event-icon" v-html="renderIcon()"></div>
      <p class="event-player fw-bold">{{ eventPlayer }}</p>
      <p v-if="eventSecondPlayer" class="event-second-player text-muted">
        {{ eventSecondPlayer }}
      </p>
      <p v-if="eventReason && eventReason !== 'Goal'" class="event-reason">
        ({{ eventReason }})
      </p>
      <p v-if="eventTypeId === 9" class="event-name">({{ eventName }})</p>
      <p v-if="eventTypeId === 5" class="score">
        {{ eventHomeGoals }} : {{ eventAwayGoals }}
      </p>
    </div>
    <!-- Away Team  -->
    <div v-else-if="home_away === 'away' && eventType" class="event-content away">
      <p v-if="eventReason && eventReason !== 'Goal'" class="event-reason">
        ({{ eventReason }})
      </p>
      <p v-if="eventTypeId === 5" class="score">
        {{ eventHomeGoals }} : {{ eventAwayGoals }}
      </p>
      <p v-if="eventTypeId === 9" class="event-name">({{ eventName }})</p>
      <p class="event-player fw-bold">{{ eventPlayer }}</p>
      <p v-if="eventSecondPlayer" class="event-second-player text-muted">
        {{ eventSecondPlayer }}
      </p>
      <div class="event-icon" v-html="renderIcon()"></div>
      <p class="event-minute fw-bold">{{ eventMinute }}</p>
    </div>
  </div>
</template>

<style>
.yellow-card {
  width: 14px;
  height: 18px;
  background-color: #ffe500;
  border-radius: 0.125rem;
  transform: rotate(15deg);
  margin-bottom: 0.25rem;
}
.red-card {
  width: 14px;
  height: 18px;
  background-color: rgb(219, 7, 0);
  border-radius: 0.125rem;
  transform: rotate(15deg);
  margin-bottom: 0.25rem;
}
.text-sm {
  font-size: 0.75rem;
}
.yellow-red-card {
  position: relative;
  width: 14px;
  height: 18px;
  background-color: #ffe500; /* Yellow */
  border-radius: 0.125rem;
  transform: rotate(15deg);
  margin-bottom: 0.25rem;
  overflow: hidden;
}

.yellow-red-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff0000; /* Red */
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.event-table {
  display: flex;
  flex-direction: column;
}
.event-content {
  display: flex;
  align-items: center;
  padding: 5px;
}
.home {
  justify-content: flex-start;
}
.away {
  justify-content: flex-end;
}
.event-minute,
.event-player,
.event-second-player,
.event-reason,
.score {
  margin: 0 5px;
}
.event-icon {
  margin: 0 10px;
}
.event-divider {
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: #f0f0f0;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
  border-radius: .25rem;
  margin: 10px 0;
}
</style>
